import { TranslationContextProvider } from "contexts/TranslationContext";
import AppRouter from "./AppRouter";
import { SessionProvider } from "contexts/SessionContext";

// Socials tracking pixels
import SnapChatPixel from 'react-snapchat-pixel';
import FaceBookPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import {useEffect} from "react";
import TagManager from 'react-gtm-module'



const tagManagerArgs = {
  gtmId: 'GTM-PWHQB2C'
}
TagManager.initialize(tagManagerArgs)
TiktokPixel.init('CR263URC77UAH29I9NEG');
SnapChatPixel.init('3004d76b-0489-4725-afe1-520066899881');
FaceBookPixel.init('428385260206888');





const AppWrapper = () => {
    // twitter pixel
    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://platform.twitter.com/oct.js";
        document.body.appendChild(script);

        script.onload = () => {
            window.twq = window.twq || function() {
                window.twq.exe ? window.twq.exe(...arguments) : window.twq.queue.push(arguments);
            };
            window.twq.version = '1.1';
            window.twq.queue = [];
            window.twq('init', 'o5yi5');
            window.twq('track', 'PageView');
        };
    }, []);

  return (
    <SessionProvider>
      <TranslationContextProvider>
        <AppRouter />
      </TranslationContextProvider>
    </SessionProvider>
  );
};

export default function App() {
  return (
    <AppWrapper />
  );
}
