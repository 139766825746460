import { LoadingOutlined } from "@ant-design/icons";
import { FormDrawer, buildInitialValues, buildValidationSchema } from "Components";
import { API_URL } from "constants";
import { withFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateNewAppMutation, useGetPersonalInformationMutation } from "services/auth";
import { PATCH_PERSONAL_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext, SET_EDIT_APPL_LOAD_FLAGS } from "./CreateRequestContext";
import { personalDetailsSchema } from "./schemas";
import moment from "moment-hijri";
import env from "shared/constants/env";
import { useTranslation } from "../../../contexts/TranslationContext";

const PersonalDetails = withFormik({
	mapPropsToValues: () => buildInitialValues(personalDetailsSchema({ maritalStatus: [], nationalities: [], titleOptions: [], dependentOptions: [] })),
	validationSchema: buildValidationSchema(personalDetailsSchema({ maritalStatus: [], nationalities: [], titleOptions: [], dependentOptions: [] })),
	enableReinitialize: true
})((props) => {
	const navigate = useNavigate();
	const { state, dispatch } = useCreateRequestContext();
	const [fetchPersonalInfo, { isLoading }] = useGetPersonalInformationMutation();
	const [createNewApp] = useCreateNewAppMutation();
	const [nationalities, setNationalities] = useState([]);
	const [maritalStatus, setMaritalStatus] = useState([]);
	const [regions, setRegions] = useState([]);
	const [cities, setCities] = useState([]);
	const [dependentOptions, setDependentOptions] = useState([]);
	const [titleOptions, setTitleOptions] = useState([]);
	const { translate } = useTranslation();
	const [initialDataLoading, setInitialDataLoading] = useState(true)

	const getUserId = () => {
		let user = null;
		try {
			user = JSON.parse(localStorage["@@TAMWEEL_USER"]);
		} catch (e) {
			navigate("/auth/sign-in");
			toast.error("Unauthorized. Please sign-in again.");
		}
		return user
	}

	const getPersonalInfoAsync = async (user) => {
		// let user;
		// try {
		// 	user = JSON.parse(localStorage["@@TAMWEEL_USER"]);
		// } catch (e) {
		// 	navigate("/auth/sign-in");
		// 	toast.error("Unauthorized. Please sign-in again.");
		// }

		if (!user) {
			navigate("/auth/sign-in");
			return;
		}
		console.log("user", user);

		const response = await fetchPersonalInfo({
			id: String(user?.USER_ID),
		})
			.unwrap()
			.catch((res) => {
				console.log("Personal info", res);
			});
		console.log(">Personal Info response", response);
		if (response) {
			const info = response?.payload?.data?.info;
			const address = response?.payload?.data?.address?.personNationalAddressInfo?.Addresses?.[0];
			const latlong = address?.ObjLatLng || '';
			const [lat, long] = latlong.trim().split(/\s+/).map(Number);

			props.setValues((prevValues) => ({
				...prevValues,
				fullName: `${info[0].FIRST_NAME} ${info[0].MIDDLE_NAME} ${info[0].LAST_NAME}`,
				address: `${address?.BuildingNumber}, ${address?.Street_L2}  ${address?.District_L2}`,
				additionalNumber: address?.AdditionalNumber,
				idExpiryDate: moment(info[0].ID_EXPIRY_DATE).format("YYYY-MM-DD"),
				postCode: address?.PostCode,
				buildingNum: address?.BuildingNumber,
				lat: lat,
				long: long,
				streetName: address?.Street,
				district_name: address?.District,
				additionalNum: address?.AdditionalNumber,
			}));
		}
	};

	const initializeData = async (user) => {
		try {
			const nationalitiesPromise = fetch(
				`${env.API_ENDPOINT}lookup/nationalities?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setNationalities(
						data.payload.rows
							.filter(nationality => {
								// Hide all nationalities if user is saudi and show only saudi arabia
								if (user && String(user.USER_ID)?.[0] === '1') {
									return String(nationality.NATIONALITYID) === '904576';
								}
								else {
									return true;
								}
							})
							.map((row) => ({
								label: row.NATIONALITY,
								labelAr: row.NATIONALITY_AR,
								value: String(row.NATIONALITYID),
							}))
					)
				)
				.catch(() => {
					toast.error("Failed to load nationalities.");
				});

			const titleOptionsPromise = fetch(
				`${env.API_ENDPOINT}lookup/title?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setTitleOptions(
						data.payload.rows.map((row) => ({
							label: row.TITLE,
							value: String(row.TITLEID),
							labelAr: row.TITLE_AR,
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load titles.");
				});

			const dependentOptionsPromise = fetch(
				`${env.API_ENDPOINT}lookup/fam-size?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setDependentOptions(
						data.payload.rows.map((row) => ({
							label: row.FAMILYSIZE,
							value: String(row.FAMILYSIZEID),
							labelAr: row.FAMILYSIZE_AR,
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load dependents.");
				});

			const maritalStatusPromise = fetch(
				`${env.API_ENDPOINT}lookup/marital-status?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setMaritalStatus(
						data.payload.rows.map((row) => ({
							label: row.MARSTAT,
							labelAr: row.MARSTAT_AR,
							value: String(row.MARSTATID),
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load marital statuses.");
				});

			const regionsPromise = fetch(`${env.API_ENDPOINT}lookup/regions?status=A`)
				.then((res) => res.json())
				.then((data) =>
					setRegions(
						data.payload.rows.map((row) => ({
							label: row.REGION,
							value: String(row.REGIONID),
							labelAr: String(row.REGION_AR),
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load regions.");
				});

			await Promise.all([
				nationalitiesPromise,
				titleOptionsPromise,
				dependentOptionsPromise,
				maritalStatusPromise,
				regionsPromise,
			]);
		} finally {
			// Set initial loading state to false after all operations are complete
			setInitialDataLoading(false);
		}
	}

	useEffect(() => {
		dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 0 });
	}, [dispatch]);

	useEffect(() => {
		dispatch({
			type: SET_STEP_VALIDITY,
			payload: {
				stepIndex: 0,
				valid: props.isValid,
			},
		});
	}, [dispatch, props.isValid]);


	useEffect(() => {
		//initializing
		const user = getUserId()
		initializeData(user)
		setTimeout(() => {
			//personal data should be loaded after all details are fetched and loaded in case of Edit App
			getPersonalInfoAsync(user);
		}, 1500);
	}, [])


	useEffect(() => {
		if (state.personalDetails && state.editApplLoadFlags && !state.editApplLoadFlags?.personalDetails) {
			console.log('editData>', state.personalDetails, state.editApplLoadFlags)
			props.setValues((prevValues) => ({
				...prevValues,
				...state.personalDetails
			}))
			//flag change (EditData loaded once)
			dispatch({
				type: SET_EDIT_APPL_LOAD_FLAGS,
				payload: {
					...state.editApplLoadFlags,
					personalDetails: true
				},
			});
		} else {
			props.setValues({
				...state.personalDetails
			})
		}
	}, [state.editApplLoadFlags]);

	useEffect(() => {
		setCities([]);
		if (props?.values?.region) {
			fetch(`${env.API_ENDPOINT}lookup/cities?districtid=${props?.values?.region}&status=A`)
				.then((res) => res.json())
				.then((res) => {
					setCities(
						res.payload.rows.map((row) => ({
							label: row.CITY,
							value: String(row.CITYID),
							labelAr: String(row.CITY_AR),
						}))
					);
				})
				.catch(() => {
					toast.error("Failed to load cities.");
				});
		}
	}, [props.values.region]);

	useEffect(() => {
		console.log(props.values)
		dispatch({
			type: PATCH_PERSONAL_DETAILS,
			payload: props.values,
		});
	}, [dispatch, props.values]);

	const schema = useMemo(() => {
		return personalDetailsSchema({
			maritalStatus,
			nationalities,
			regions,
			cities,
			titleOptions,
			dependentOptions,
		});
	}, [maritalStatus, nationalities, regions, cities]);

	if (initialDataLoading || isLoading) {
		return (
			<div className='p-5 bg-white flex flex-col gap-4'>
				<div className='flex gap-4'>
					<LoadingOutlined />
					<span>{translate("CREATE_REQUEST.LOADING_DETAILS")}</span>
				</div>
			</div>
		);
	}

	return (
		<>
			<h2 className='font-bold text-xl mb-4'>{translate("CREATE_REQUEST.PERSONAL_DETAILS")}</h2>
			<div className='mb-4'>
				<FormDrawer
					schema={{
						fields: schema,
					}}
				/>
			</div>
		</>
	);
});

export default PersonalDetails;
